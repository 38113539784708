import React from "react";
import '../CallVotingImageModal/CallVotingImageModal.css';
import closeIcon from '../../../img/DeleteIconForGallery.svg';

const CallVotingImageModal = (props) => {

    const {
        onCloseModal,
        selectedImage,
        modalImageRef
    } = props;

    return (
        <div className="call-voting-image-modal__container">
            <div ref={modalImageRef} className="call-voting-image-modal">
                <div className="call-voting-image-modal__title" title={"Закрыть"}>
                    <img onClick={onCloseModal} src={closeIcon} alt={'иконка крестик'}/>
                </div>
                <img className="call-voting-image-modal__image" src={selectedImage} alt={'картинка'}/>
            </div>
        </div>
    )
}
export default CallVotingImageModal;