import React from 'react';
import './ReplacementMaterialQuestionOnImages.css';

const ReplacementMaterialQuestionOnImages = (props) => {

   const {
        materialsQuestion,
        selectImage,
        disableClickImage
   } = props;

    return (
        <div className="replacement-material-question-on-images">
            {materialsQuestion?.map((el, i) => {
                return (
                    <div key={i} className='replacement-material-question-on-images__item'>
                        <img
                             onClick={() => selectImage(i, el.value)}
                             className={disableClickImage === false ? "replacement-material-question-on-images__item-img" : "replacement-material-question-on-images__item-img-disable"}
                             data-src={el?.value}
                             src={el?.value}
                             alt={"Открытка"}
                             title={el?.title}
                        />
                        <p className="replacement-material-question-on-images__item-title">{el?.title}</p>
                    </div>
                )
            })}
        </div>
    )
}
export default ReplacementMaterialQuestionOnImages;